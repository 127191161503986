import { Controller } from 'stimulus';

const selectionLimit = 4;

export default class extends Controller {
  static targets = ['checkbox'];

  static classes = [];

  static values = {};

  limitSelection(ev) {
    if (this._selectedCategoriesCount() > selectionLimit) {
      /* eslint-disable-next-line no-param-reassign */
      ev.target.checked = false;
      ev.preventDefault();
    }
  }

  _selectedCategoriesCount() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked).length;
  }
}
