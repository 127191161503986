import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['destroy'];

  static classes = ['hidden'];

  static values = {};

  remove() {
    if (this.hasDestroyTarget) {
      this.destroyTarget.checked = true;

      this.element.classList.add(this.hiddenClass);
    } else {
      this.element.remove();
    }
  }
}
