import { Controller } from 'stimulus';

const cssIndexPropName = '--active-index';
const cssSpacingPropName = '--slides-spacing';

export default class extends Controller {
  static targets = ['container', 'prevControl', 'nextControl'];

  static classes = ['controlInactive'];

  static values = { activeSlide: Number };

  initialize() {
    this.slideSpacing = this._slideSpacing();
    this._resetSettings = this._resetSettings.bind(this);
  }

  connect() {
    window.addEventListener('resize', this._resetSettings);
    window.addEventListener('orientationchange', this._resetSettings);
  }

  disconnect() {
    window.removeEventListener('resize', this._resetSettings);
    window.removeEventListener('orientationchange', this._resetSettings);
  }

  next(ev) {
    ev.preventDefault();
    if (this.activeSlideValue < this._slidesCount() - 1) {
      this.activeSlideValue += 1;
    }
  }

  prev(ev) {
    ev.preventDefault();
    if (this.activeSlideValue > 0) {
      this.activeSlideValue -= 1;
    }
  }

  activeSlideValueChanged() {
    this.containerTarget.style.setProperty(cssIndexPropName, this.activeSlideValue);
    this._resetControls();
  }

  _resetControls() {
    if (this.activeSlideValue === 0) {
      this.prevControlTarget.classList.add(this.controlInactiveClass);
    } else {
      this.prevControlTarget.classList.remove(this.controlInactiveClass);
    }

    if (this.activeSlideValue === this._slidesCount() - 1) {
      this.nextControlTarget.classList.add(this.controlInactiveClass);
    } else {
      this.nextControlTarget.classList.remove(this.controlInactiveClass);
    }
  }

  _slidesCount() {
    const scrollWidth = this.containerTarget.scrollWidth - this.slideSpacing;
    const offsetWidth = this.containerTarget.offsetWidth + this.slideSpacing;
    return Math.ceil(scrollWidth / offsetWidth);
  }

  _slideSpacing() {
    return parseInt(getComputedStyle(this.containerTarget).getPropertyValue(cssSpacingPropName), 10) || 0;
  }

  _resetSettings() {
    this.slideSpacing = this._slideSpacing();
    this.activeSlideValue = 0;
    this._resetControls();
  }
}
