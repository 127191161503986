import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  static classes = [];

  static values = {};

  dismiss() {
    this.element.parentNode.removeChild(this.element);
  }
}
