import 'core-js/es/promise';
import 'core-js/es/array/from';
import 'core-js/es/array/find';
import 'core-js/es/object/assign';
import 'core-js/es/string/includes';

// .closest()
// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  // eslint-disable-next-line func-names
  Element.prototype.closest = function (s) {
    let el = this;

    if (!document.documentElement.contains(el)) return null;

    do {
      if (el.matches(s)) return el;

      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);

    return null;
  };
}

// .remove()
// https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
// eslint-disable-next-line func-names
(function (arr) {
  // eslint-disable-next-line func-names
  arr.forEach(function (item) {
    // eslint-disable-next-line no-prototype-builtins
    if (item.hasOwnProperty('remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode !== null) this.parentNode.removeChild(this);
      },
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
