import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  static classes = [];

  static values = {};

  removeFact() {
    this.element.remove();
  }
}
