import { Controller } from 'stimulus';

const descriptionMaxHeightInit = 95;
const descriptionMaxHeightCollapsed = 100;
const offsetOpenHeight = 7;

export default class extends Controller {
  static values = {
    isActive: Boolean,
    isOpen: Boolean,
    maxHeight: Number,
  };

  static classes = ['active', 'open'];

  static targets = ['content', 'readMore'];

  connect() {
    this._initialize();
  }

  _initialize() {
    if (this.hasContentTarget && this.hasReadMoreTarget) {
      if (this.contentTarget.offsetHeight > descriptionMaxHeightInit) {
        this.isActiveValue = true;
        this.contentTarget.classList.add(this.activeClass);
        this.readMoreTarget.classList.add(this.activeClass);
        this.readMoreTarget.removeAttribute('hidden');
        this.contentTarget.style.height = `${descriptionMaxHeightCollapsed}px`;
      }
    }
  }

  toggle() {
    if (this.isOpenValue) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    this.isOpenValue = true;
    this.contentTarget.classList.add(this.openClass);
    this.maxHeightValue = this.contentTarget.scrollHeight;
    this.contentTarget.style.height = `${this.maxHeightValue + offsetOpenHeight}px`;
    this.readMoreTarget.classList.add(this.openClass);
    this.readMoreTarget.innerHTML = this.readMoreTarget.getAttribute('data-campaign-card-less');
  }

  close() {
    this.isOpenValue = false;
    this.contentTarget.classList.remove(this.openClass);
    this.contentTarget.style.height = `${descriptionMaxHeightCollapsed}px`;
    this.readMoreTarget.classList.remove(this.openClass);
    this.readMoreTarget.innerHTML = this.readMoreTarget.getAttribute('data-campaign-card-more');
  }
}
