import { Controller } from 'stimulus';

const openingEventName = 'navigation-opening';

export default class extends Controller {
  static targets = [];

  static classes = ['open'];

  static values = { isNested: Boolean };

  connect() {
    this.close = this.close.bind(this);
    window.addEventListener(openingEventName, this.close);
    document.body.addEventListener('modal-open', this.close);
  }

  disconnect() {
    window.removeEventListener(openingEventName, this.close);
    document.body.removeEventListener('modal-open', this.close);
  }

  toggle(ev) {
    if (this.element.classList.contains(this.openClass)) {
      this.close(ev);
    } else {
      this.open(ev);
    }
  }

  close(ev) {
    ev.preventDefault();
    this.element.classList.remove(this.openClass);
  }

  open(ev) {
    ev.preventDefault();
    if (!this.isNestedValue) {
      window.dispatchEvent(new Event(openingEventName));
    }
    this.element.classList.add(this.openClass);
  }
}
