import { Controller } from 'stimulus';

const invalidClass = 'is-invalid';

export default class extends Controller {
  static targets = ['errors'];

  static classes = [];

  static values = { changeEventName: String };

  emitChangeEvent() {
    if (!this.hasChangeEventNameValue) return;

    this.element.dispatchEvent(new Event(this.changeEventNameValue, { bubbles: true }));
  }

  toggleDisable() {
    if (this.element.getAttribute('disabled')) {
      this.element.removeAttribute('disabled');
    } else {
      this.element.setAttribute('disabled', 'disabled');
    }
  }

  resetValidation() {
    this.element.classList.remove(invalidClass);

    if (this.hasErrorsTarget) {
      this.element.removeChild(this.errorsTarget);
    }
  }
}
