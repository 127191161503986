import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['memberTemplate'];

  static classes = ['hidden'];

  static values = {};

  addMember(ev) {
    const memberTemplate = this.memberTemplateTarget.content.cloneNode(true).querySelector('*');
    this._prepareMemberTemplate(memberTemplate);
    this.element.insertBefore(memberTemplate, ev.currentTarget);
  }

  _prepareMemberTemplate(template) {
    const index = new Date().getTime();
    template.classList.remove(this.hiddenClass);
    const inputs = template.querySelectorAll('input, textarea');
    inputs.forEach(input => {
      input.setAttribute('id', this._replaceIndex(input.id, index));
      input.setAttribute('name', this._replaceIndex(input.name, index));
    });
    const labels = template.querySelectorAll('label');
    labels.forEach(label => {
      label.setAttribute('for', this._replaceIndex(label.getAttribute('for'), index));
    });
  }

  _replaceIndex(text, index) {
    return text.replace('[0]', `[${index}]`).replace('_0_', `_${index}_`);
  }
}
