import { Controller } from 'stimulus';

function targetNameToHasTargetName(targetName) {
  return `has${targetName.charAt(0).toUpperCase() + targetName.slice(1)}`;
}

export default class extends Controller {
  #timer;

  #secondsLeft;

  static values = {
    secondsLeft: Number,
  };

  static targets = [
    'hours',
    'minutes',
    'seconds',
    'hoursFormatted',
    'minutesFormatted',
    'secondsFormatted',
    'hoursLabel',
    'minutesLabel',
    'secondsLabel',
  ];

  connect() {
    this.startTimer(this.secondsLeftValue);
  }

  disconnect() {
    window.clearTimeout(this.#timer);
  }

  startTimer = secondsLeft => {
    if (!secondsLeft) {
      return;
    }

    this.#secondsLeft = secondsLeft - 1;

    const data = this.getTimeLeftData(secondsLeft);
    this.updateElement('hoursTarget', data.hours);
    this.updateElement('minutesTarget', data.minutes);
    this.updateElement('secondsTarget', data.secondes);
    this.updateElement('hoursFormattedTarget', data.hoursFormatted);
    this.updateElement('minutesFormattedTarget', data.minutesFormatted);
    this.updateElement('secondsFormattedTarget', data.secondsFormatted);
    this.updateElement('hoursLabelTarget', data.hoursLabel);
    this.updateElement('minutesLabelTarget', data.minutesLabel);
    this.updateElement('secondsLabelTarget', data.secondsLabel);

    this.#timer = window.setTimeout(() => this.startTimer(this.#secondsLeft), 1000);
  };

  getTimeLeftData(secondsLeft) {
    const hours = parseInt(secondsLeft / 3600, 10);
    const minutes = parseInt((secondsLeft % 3600) / 60, 10);
    const secondes = parseInt(secondsLeft % 60, 10);

    return {
      hours,
      minutes,
      secondes,
      hoursFormatted: String(hours).padStart(2, '0'),
      minutesFormatted: String(minutes).padStart(2, '0'),
      secondsFormatted: String(secondes).padStart(2, '0'),
      hoursLabel: hours === 1 ? 'hour' : 'hours',
      minutesLabel: minutes === 1 ? 'minute' : 'minutes',
      secondsLabel: minutes === 1 ? 'second' : 'seconds',
    };
  }

  updateElement(targetName, value) {
    const hasMenuTargetFieldName = targetNameToHasTargetName(targetName);
    if (!this[hasMenuTargetFieldName]) return;
    this[targetName].textContent = value;
  }
}
