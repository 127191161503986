import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input'];

  static classes = ['active'];

  static values = {};

  activate() {
    this.element.classList.add(this.activeClass);

    if (this.hasInputTarget) {
      this.inputTarget.focus();
    }
  }

  deactivate() {
    this.element.classList.remove(this.activeClass);
  }

  toggle() {
    if (this.element.classList.contains(this.activeClass)) {
      this.deactivate();
    } else {
      this.activate();
    }
  }
}
