import { Controller } from 'stimulus';
import filestackClient from '../filestack';

const dropzoneSettings = {
  maxFiles: 10,
  uploadInBackground: false,
  fromSources: ['local_file_system', 'url', 'googledrive', 'webcam'],
};

export default class extends Controller {
  static targets = ['dropzone', 'fileTemplate'];

  static classes = [];

  static values = {};

  connect() {
    this._createDropzone();
  }

  openPicker() {
    const settings = {
      ...dropzoneSettings,
      onUploadDone: response => {
        this._addFilesToOutput(response.filesUploaded);
      },
    };
    filestackClient.picker(settings).open();
  }

  _createDropzone() {
    if (!this.hasDropzoneTarget) return;

    const settings = {
      ...dropzoneSettings,
      displayMode: 'dropPane',
      container: this.dropzoneTarget,
      dropPane: {
        overlay: false,
        onSuccess: response => {
          this._addFilesToOutput(response);
        },
      },
    };

    filestackClient.picker(settings).open();
  }

  _addFilesToOutput(files) {
    this._emitFilesAddedEvent(files);

    if (!this.hasFileTemplateTarget) return;

    files.forEach(this._addFile.bind(this));
  }

  _emitFilesAddedEvent(files) {
    const event = new CustomEvent('files-uploaded', { detail: { uploadedFiles: files } });
    this.element.dispatchEvent(event);
  }

  _addFile(file, index) {
    const fileIndex = `${new Date().getTime()}${index}`;
    const fileTemplate = this.fileTemplateTarget.content.cloneNode(true).querySelector('*');
    this.fileTemplateTarget.parentNode.insertBefore(fileTemplate, this.fileTemplateTarget);
    Promise.resolve().then(() => {
      // wait until stimulus controller attaches
      fileTemplate.dispatchEvent(new CustomEvent('file-added', { detail: { index: fileIndex, file } }));
    });
  }
}
