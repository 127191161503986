import { Controller } from 'stimulus';

const descriptionMaxHeight = 95;

export default class extends Controller {
  static targets = ['wrapper', 'readMore'];

  static classes = ['hidden'];

  static values = {};

  connect() {
    this._hideReadMore();
  }

  _hideReadMore() {
    if (!this.hasWrapperTarget || !this.hasReadMoreTarget) return;

    if (this.wrapperTarget.offsetHeight <= descriptionMaxHeight) {
      this.readMoreTarget.classList.add(this.hiddenClass);
    }
  }
}
