import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['factTemplate'];

  static classes = [];

  static values = {};

  addRow() {
    const factField = this.factTemplateTarget.content.cloneNode(true).querySelector('*');
    this.factTemplateTarget.parentNode.insertBefore(factField, this.factTemplateTarget);
  }
}
