import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['label', 'id', 'handle', 'filename', 'mimetype', 'size', 'url', 'destroy'];

  static classes = ['hidden'];

  static values = { placeholder: String };

  removeFile() {
    if (this.hasDestroyTarget) {
      this.destroyTarget.checked = true;
      this.element.classList.add(this.hiddenClass);
    } else {
      this.element.remove();
    }
  }

  attachFile(ev) {
    if (!this.hasPlaceholderValue) return;

    const { index, file } = ev.detail;

    this.labelTarget.innerText = file.filename;
    this._replaceIndexes(this.idTarget, index);
    this._replaceIndexes(this.handleTarget, index);
    this._replaceIndexes(this.filenameTarget, index);
    this._replaceIndexes(this.mimetypeTarget, index);
    this._replaceIndexes(this.sizeTarget, index);
    this._replaceIndexes(this.urlTarget, index);
    this.idTarget.value = '';
    this.handleTarget.value = file.handle;
    this.filenameTarget.value = file.filename;
    this.mimetypeTarget.value = file.mimetype;
    this.sizeTarget.value = file.size;
    this.urlTarget.value = file.url;

    this.element.classList.remove(this.hiddenClass);
    this.placeholderValue = undefined;
  }

  _replaceIndexes(input, index) {
    input.setAttribute('id', input.id.replace(this.placeholderValue, index));
    input.setAttribute('name', input.name.replace(this.placeholderValue, index));
  }
}
