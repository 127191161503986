import { Controller } from 'stimulus';
import client from '../filestack';

const defaultSettings = {
  maxFiles: 1,
  uploadInBackground: false,
  fromSources: ['local_file_system', 'url', 'googledrive', 'webcam'],
  transformations: {
    crop: {
      force: true,
    },
  },
};

export default class extends Controller {
  static targets = ['id', 'handle', 'filename', 'mimetype', 'size', 'url', 'preview'];

  static classes = ['notCropped'];

  static values = {};

  openPicker(ev) {
    const isNotCropped = 'notForced' in ev.currentTarget.dataset;
    const { customRatio = 298 / 184 } = ev.currentTarget.dataset;

    const settings = { ...defaultSettings };

    settings.onUploadDone = response => {
      this._handleUploadDone(response, ev, isNotCropped);
    };

    settings.transformations.crop = {
      aspectRatio: parseFloat(customRatio),
      force: !isNotCropped,
    };

    client.picker(settings).open();
  }

  _handleUploadDone(response, event, isNotCropped) {
    if (response.filesUploaded.length > 0) {
      const { url } = response.filesUploaded[0];

      if (isNotCropped) {
        this.element.classList.add(this.notCroppedClass);
      }

      if (this.hasPreviewTarget) {
        this.previewTarget.style.backgroundImage = `url('${url}')`;
      }

      this._updateProfilePictureUrl(response.filesUploaded[0]);
    }
  }

  _updateProfilePictureUrl({ handle, filename, mimetype, size, url }) {
    this.handleTarget.value = handle;
    this.filenameTarget.value = filename;
    this.mimetypeTarget.value = mimetype;
    this.sizeTarget.value = size;
    this.urlTarget.value = url;
    this.idTarget.value = null;

    const changeEvent = new Event('change');
    this.idTarget.dispatchEvent(changeEvent);
    this.handleTarget.dispatchEvent(changeEvent);
    this.filenameTarget.dispatchEvent(changeEvent);
    this.mimetypeTarget.dispatchEvent(changeEvent);
    this.sizeTarget.dispatchEvent(changeEvent);
    this.urlTarget.dispatchEvent(changeEvent);
  }
}
