import { Controller } from 'stimulus';
import client from '../filestack';

export default class extends Controller {
  static targets = [];

  static classes = [];

  static values = {};

  filterFileTypes(ev) {
    const { file } = ev;
    if (!file.type.includes('image')) {
      ev.preventDefault();
    }
  }

  addAttachment(ev) {
    client
      .upload(ev.attachment.file, {
        onProgress(progress) {
          ev.attachment.setUploadProgress(progress.totalPercent);
        },
      })
      .then(result => {
        ev.attachment.setAttributes({
          url: result.url,
          href: result.url,
        });
      });
  }
}
