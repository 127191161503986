/* global grecaptcha */

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['recaptcha'];

  static classes = [];

  static values = {};

  connect() {
    window.recaptchaReady = this.initRecaptcha.bind(this);
  }

  initRecaptcha() {
    grecaptcha.render(this.recaptchaTarget, { sitekey: window.global.recaptchaApiKey });
  }

  handleSubmitCompletion(ev) {
    const [xhr] = ev.detail;

    switch (xhr.status) {
      case 200:
        this.element.innerHTML = 'Your request has been sent.';
        break;
      case 422: {
        const temp = document.createElement('div');
        temp.innerHTML = xhr.responseText;
        this.element.innerHTML = temp.querySelector(`[data-controller="${this.identifier}"]`).innerHTML;
        this.initRecaptcha();
        break;
      }
      default:
        this.element.innerHTML = 'Something went wrong, please try again later.';
    }
  }
}
