import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['row', 'rowTemplate'];

  static classes = [];

  static values = { placeholder: String };

  addRow() {
    const rowTemplate = this.rowTemplateTarget.content.cloneNode(true).querySelector('*');
    const index = this.rowTargets.length;
    const inputs = rowTemplate.querySelectorAll('input, textarea');
    inputs.forEach(input => {
      input.setAttribute('id', input.id.replace(this.placeholderValue, index));
      input.setAttribute('name', input.name.replace(this.placeholderValue, index));
    });
    const labels = rowTemplate.querySelectorAll('label');
    labels.forEach(label => {
      label.setAttribute('for', label.getAttribute('for').replace(this.placeholderValue, index));
    });

    this.element.insertBefore(rowTemplate, this.rowTemplateTarget);
  }
}
