import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['errorWrapper', 'formWrapper'];

  static classes = [];

  static values = {};

  handleSubmitCompletion(ev) {
    const [xhr] = ev.detail;
    const responseJSON = JSON.parse(xhr.response);

    if (xhr.status === 200 && responseJSON.success) {
      this._handleSuccess(responseJSON);
    } else {
      this._handleError(responseJSON);
    }
  }

  _handleSuccess(data) {
    if ('redirect_url' in data) {
      window.location = data.redirect_url;
    } else {
      this.element.innerHTML = data.message;
    }
  }

  _handleError(data) {
    this._clearPreviousErrors();

    if (data.errors) {
      Object.entries(data.errors).forEach(([field, error]) => {
        const errors = Array(error);
        this._addError(this.element.querySelector(`input[name*="[${field}]"]`), errors.join('. '));
      });
    } else {
      this._addError(this.formWrapperTarget.firstChild, 'Something went wrong, please try again later.');
    }
  }

  _addError(element, errorMessage) {
    const errorElement = this.errorWrapperTarget.cloneNode(true);
    errorElement.innerHTML = errorMessage;
    element.parentNode.insertBefore(errorElement, element);
  }

  _clearPreviousErrors() {
    const [, ...errors] = this.errorWrapperTargets;
    errors.forEach(error => error.parentNode.removeChild(error));
  }
}
