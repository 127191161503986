import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'submit'];

  static classes = [];

  static values = {};

  reset() {
    this.formTarget.reset();
  }

  validate() {
    let isValid = true;
    Array.from(this.formTarget.elements).forEach(el => {
      // do not use form.reportValidity, because we do not want to spam user with messages
      isValid = isValid && el.checkValidity();
    });

    if (isValid) {
      this.submitTarget.removeAttribute('disabled');
    } else {
      this.submitTarget.setAttribute('disabled', 'disabled');
    }
  }
}
