import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container', 'overlay'];

  static classes = ['closed', 'noscroll'];

  static values = {};

  open(ev) {
    const modalName = this._getTarget(ev.target);
    const container = this._findTargetContainer(modalName);

    if (!container) return;

    this.containerTargets.forEach(modal => modal.classList.add(this.closedClass));

    document.body.classList.add(this.noscrollClass);
    this.overlayTarget.classList.remove(this.closedClass);
    container.classList.remove(this.closedClass);
    container.dispatchEvent(new Event('modal-open', { bubbles: true })); // required by fa-form-controller.js
  }

  close() {
    const container = this._findOpenContainer();
    if (container) {
      document.body.classList.remove(this.noscrollClass);
      container.classList.add(this.closedClass);
      this.overlayTarget.classList.add(this.closedClass);
      container.dispatchEvent(new Event('modal-close', { bubbles: true })); // required by fa-form-controller.js
      this._resetEmbed(container);
    }
  }

  _findOpenContainer() {
    return this.containerTargets.find(target => !target.classList.contains(this.closedClass));
  }

  _findTargetContainer(name) {
    return this.containerTargets.find(target => target.dataset.modalName === name);
  }

  _getTarget(button) {
    if (!('modalName' in button.dataset)) return null;

    return button.dataset.modalName;
  }

  _resetEmbed(container) {
    if (container.querySelector('iframe,video,audio')) {
      const modalHTML = container.innerHTML;
      /* eslint-disable no-param-reassign */
      container.innerHTML = '';
      container.innerHTML = modalHTML;
      /* eslint-enable no-param-reassign */
    }
  }
}
