import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const $wrapper = document.querySelector('.c-hero__wrapper');
    const $elements = [$wrapper.querySelector('p'), ...$wrapper.querySelectorAll('h1 > span')];
    const $images = [...$wrapper.querySelectorAll('img')];

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
    };

    const callback = entries => {
      entries.forEach(entry => {
        $elements.forEach($element => {
          $element.style.transform = `translateY(${100 * (1 - entry.intersectionRatio)}px)`;
        });

        $images.forEach($element => {
          $element.style.transform = `translateY(${
            -100 * (1 - entry.intersectionRatio) - 70
          }px) translateZ(-1px) scale(2)`;
        });
      });
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe($wrapper);
  }
}
